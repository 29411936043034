@font-face {
font-family: 'caveat';
src: url(/_next/static/media/13bd44ccb638a74c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'caveat Fallback';src: local("Arial");ascent-override: 128.40%;descent-override: 40.12%;line-gap-override: 0.00%;size-adjust: 74.77%
}.__className_02c4e0 {font-family: 'caveat', 'caveat Fallback';font-weight: 400;font-style: normal
}

@font-face {
font-family: 'futuraNowText';
src: url(/_next/static/media/2d5735036d34b042-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'futuraNowText';
src: url(/_next/static/media/db3cec1bfaaf632a-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'futuraNowText';
src: url(/_next/static/media/97459cc136502cfc-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'futuraNowText Fallback';src: local("Arial");ascent-override: 125.50%;descent-override: 31.81%;line-gap-override: 0.00%;size-adjust: 103.75%
}.__className_ada352 {font-family: 'futuraNowText', 'futuraNowText Fallback'
}

@font-face {
font-family: 'merlodNorme';
src: url(/_next/static/media/c6aa24e07a77f4d4-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'merlodNorme Fallback';src: local("Arial");ascent-override: 103.58%;descent-override: 25.90%;line-gap-override: 6.47%;size-adjust: 77.23%
}.__className_8e81b8 {font-family: 'merlodNorme', 'merlodNorme Fallback';font-weight: 700;font-style: normal
}

